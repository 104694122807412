import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { ColumnSeparator, TableEmpty } from './styles';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

// import { GridColumns } from '@material-ui/data-grid';
export interface CustomGridColumns {
  field: string;
  headerName: string;
  width: number;
  options?: any[];
  minWidth?: number;
  align?: string;
  format?: string;
  fixed?: boolean;
  sortable?: boolean;
  disableColumnMenu?: boolean;
  renderCell?: (row: any) => void;
  fixedDirection?: string;
}
type tooltipProps = { label: string; keyColum: string; separator?: string };

interface FormProps {
  rows: any[];
  key: string;
  tooltip?: tooltipProps[];
  columns: CustomGridColumns[];
  loading?: boolean;
  isEmptyLineMessage?: boolean;
  emptyLineMessage?: string;
}

/**
 * Linhas e colunas para exemplo
 */

/**
 * @param rows Array de objetos contendo as linhas da tabela (as keys dos objetos devem ser iguais as ids das colunas)
 * @param columns Array de objetos contendo as colunas
 * @param columns Para fixar uma coluna basta passar uma chave chamada fixed com o valor true e Left com 0 (também é necessário passar um width e um minWidth)
 * @param columns Caso mais de uma coluna seja fixa, a coluna seguinte deverá receber o valor de Left igual ao minWidth da coluna anterior
 * @returns Componente DataGrid personalizado
 */

const CustomDataGrid: React.FC<FormProps> = (props) => {
  const {
    rows,
    columns,
    key,
    tooltip = [],
    loading = false,
    emptyLineMessage = '',
    isEmptyLineMessage = false,
  } = props;
  // const rows = ExRows;
  // const columns = ExColumns;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const tableRef = useRef<any>();

  useEffect(() => {
    if (tableRef) {
      tableRef.current.addEventListener('scroll', handleScroll);
    }
  }, [tableRef]);

  useEffect(() => {
    if (rows.length === 0) {
      setPage(0);
    }
  }, [rows]);

  const handleScroll = useCallback(() => {
    // console.log(tableRef.current.scrollLeft);
  }, []);

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef.current.scrollTop = 0;
  };
  function formatType(
    item: any,
    type: string | undefined,
    options: any | undefined,
  ) {
    if (type === 'money') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(item);
    }
    if (type === 'dta') {
      return createDateWithoutTimezone(item).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    }
    if (type === 'dtatime') {
      return new Date(item).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    }
    if (type === 'number') {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(item);
    }
    if (type === 'number3') {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      }).format(item);
    }
    if (options !== undefined) {
      const Item = options.find(
        (i: { value: number; label: string }) => i.value === item,
      );
      return Item.label;
    }
    return item;
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createTooltip = (row: any) => {
    let title = '';
    // eslint-disable-next-line no-restricted-syntax
    for (const objtooltip of tooltip) {
      const { label, keyColum, separator } = objtooltip;
      title += `${label} ${row[keyColum]} ${separator || ''}`;
    }
    return title;
  };

  const LinearProgressBar: React.FC = () => {
    return (
      <LinearProgress
        style={{
          left: '0',
          right: '0',
          background: '#c2a0f8',
          color: '#8850BF',
          position: 'sticky',
        }}
      />
    );
  };

  return (
    <>
      <Paper
        style={{
          width: '100%',
          height: '430px',

          flex: 1,
          zIndex: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TableContainer
          style={{
            flex: 1,
            zIndex: 0,
          }}
          ref={tableRef}
          className="tableNFOutrasOperacoesContainer"
        >
          {/* <TableContainer ref={tableRef}> */}
          <Table stickyHeader key={key} id="tableNFOutrasOperacoes">
            <TableHead>
              {/* <TableRow> */}
              {/* <TableCell align="center" colSpan={3} /> */}
              {/* <TableCell align="center" colSpan={2}>
                  Tipos
                </TableCell> */}
              {/* </TableRow> */}
              <TableRow>
                {columns.map((column) => {
                  if (column.fixed)
                    return (
                      <TableCell
                        key={column.field}
                        className="text-truncate"
                        style={{
                          position: 'sticky',
                          right: 0,
                          width: column.width,
                          minWidth: column.width,
                          background: '#57069e',
                          zIndex: 2,
                          textAlign: 'center',
                        }}
                      >
                        {column.headerName}
                        {/* <ColumnSeparator>
                          <svg
                            className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M11 19V5h2v14z" />
                          </svg>
                        </ColumnSeparator> */}
                      </TableCell>
                    );
                  return (
                    <TableCell
                      key={column.field}
                      className="text-truncate"
                      style={{
                        minWidth: column.minWidth
                          ? column.minWidth
                          : column.width,
                        position: 'sticky',
                        zIndex: 2,
                      }}
                    >
                      {column.headerName}
                      <ColumnSeparator>
                        <svg
                          className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                          // focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M11 19V5h2v14z" />
                        </svg>
                      </ColumnSeparator>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows

                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      // eslint-disable-next-line react/no-array-index-key
                      key={rowIndex}
                    >
                      {columns.map((column) => {
                        if (column.fixed)
                          return (
                            <TableCell
                              key={column.field}
                              style={{
                                position: 'sticky',
                                right: 0,
                                width: column.width,
                                minWidth: column.width,
                                background: '#ffffff',
                                zIndex: 0,
                              }}
                            >
                              {column.renderCell
                                ? column.renderCell(row)
                                : formatType(
                                    row[column.field],
                                    column?.format,
                                    column?.options,
                                  )}
                            </TableCell>
                          );
                        return (
                          <Tooltip
                            title={createTooltip(row)}
                            placement="bottom"
                          >
                            <TableCell key={column.field}>
                              {column.renderCell
                                ? column.renderCell(row)
                                : formatType(
                                    row[column.field],
                                    column?.format,
                                    column?.options,
                                  )}
                            </TableCell>
                          </Tooltip>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {isEmptyLineMessage && rows.length === 0 && (
            <TableEmpty>{emptyLineMessage}</TableEmpty>
          )}
          {loading && <LinearProgressBar />}
        </TableContainer>

        <TablePagination
          // style={
          //   {
          //     position: 'absolute',
          //     right: 0,
          //     bottom: 0,
          //     width: '100%',
          //     overflow: 'auto',
          //   }
          // }
          // rowsPerPageOptions={[5, 10, 25, 100]}
          rowsPerPageOptions={[-1]}
          onPageChange={handleChangePage}
          count={rows.length}
          page={page}
          rowsPerPage={rowsPerPage}
        />
        {/* <div
          className="d-flex"
          style={{
            position: 'relative',
            alignSelf: 'end',
            bottom: 0,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div />
          <TablePagination
            // style={
            //   {
            //     position: 'absolute',
            //     right: 0,
            //     bottom: 0,
            //     width: '100%',
            //     overflow: 'auto',
            //   }
            // }
            rowsPerPageOptions={[5, 10, 25, 100]}
            onChangePage={handleChangePage}
            onPageChange={handleChangePage}
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </div> */}
      </Paper>
    </>
  );
};

export default CustomDataGrid;
