import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useRef } from 'react';
import { ListProps } from '../protocols';
import { Content, Option } from '../styles';

export const Lista: React.FC<ListProps> = ({
  ListaWidth,
  inputRef,
  setIsOpen,
  filter,
  setFilter,
  selected,
  setSelected,
  options,
  changeSelected,
}) => {
  /**
   * wrapperRef
   * Ref da lista de opções utilizado para fechar a lista ao clicar fora
   */
  const wrapperRef = useRef(null);

  /**
   * useCloseOptions
   * Fecha lista de opções ao clicar fora
   */
  function useCloseOptions(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (inputRef) inputRef.current.value = '';
          setFilter('');
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useCloseOptions(wrapperRef);

  /**
   * handleClick
   * Seleciona item da lista
   */
  const handleClick = useCallback(
    (option: { label: string; value: number | string }) => {
      setSelected(option);
      changeSelected(option);
      setIsOpen(false);
    },
    [changeSelected, setIsOpen, setSelected],
  );

  /**
   * renderOptions
   * Renderiza lista de opções
   */
  const renderOptions = useCallback(() => {
    if (options !== undefined) {
      if (options.length <= 0) {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      const newOptions = options.filter((option) =>
        option.label
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
      );
      if (newOptions.length <= 0) {
        return (
          <Option key={nanoid()} className="not-found">
            Nenhum registro encontrado.
          </Option>
        );
      }
      return newOptions.map((option) => {
        return (
          <Option
            key={String(option.value)}
            style={{ textOverflow: 'ellipsis' }}
            className={selected.value === option.value ? 'selected' : ''}
            onClick={() => {
              if (option.value === -1) return;
              handleClick(option);
              if (inputRef) inputRef.current.value = option.label;
              setFilter('');
            }}
            onKeyPress={() => {
              handleClick(option);
              if (inputRef) inputRef.current.value = option.label;
              setFilter('');
            }}
          >
            {option.label}
          </Option>
        );
      });
    }
    return <Option className="not-found">Nenhum registro encontrado.</Option>;
  }, [filter, handleClick, inputRef, options, selected.value, setFilter]);

  return (
    <Content ListaWidth={ListaWidth}>
      <ul ref={wrapperRef}>{options && renderOptions()}</ul>
    </Content>
  );
};
