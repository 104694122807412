import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { Lista } from './Lista';
import { InputSelectProps } from './protocols';
import { InputContainer } from './styles';

export const InputSelect: React.FC<InputSelectProps> = ({
  label = '',
  placeholder = '',
  ListaWidth = '100%',
  name,
  register,
  isError,
  control,
  changeSelected,
  options,
  ...rest
}) => {
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef: any = useRef(null);
  /**
   * selected, setSelected
   * Opção selecionada da lista
   */
  const [selected, setSelected] = useState<any>({
    label: undefined,
    value: undefined,
  });
  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState<string>('');

  return (
    <>
      <InputContainer>
        <Form.Group>
          {label && <Form.Label>{label}</Form.Label>}

          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({
              field: { value, onChange },
              fieldState: { isTouched, isDirty },
            }) => {
              if (inputRef && inputRef.current) {
                /** A condicional abaixo limpa o input */
                if (
                  value.label !== selected.label &&
                  inputRef.current.placeholder !== placeholder
                ) {
                  inputRef.current.value = '';
                  inputRef.current.placeholder = placeholder;
                }

                if (value.label) {
                  inputRef.current.placeholder = value.label;
                }
                if (!value.label) {
                  inputRef.current.placeholder = placeholder;
                }
              }
              return (
                <>
                  <label className="select-container">
                    <input
                      {...register(name)}
                      type="text"
                      className={
                        isError ? 'form-control is-invalid' : 'form-control'
                      }
                      placeholder={placeholder}
                      onChange={async (
                        event: ChangeEvent<HTMLInputElement>,
                      ) => {
                        setFilter(event.target.value.toUpperCase());
                        onChange(event.target.value.toUpperCase());
                      }}
                      autoComplete="off"
                      onClick={(event: any) => {
                        if (selected.label) {
                          event.target.placeholder = selected.label;
                        }
                        event.target.value = '';
                        setIsOpen(true);
                      }}
                      ref={inputRef}
                      {...rest}
                    />
                    <div className="drop-indicator">
                      <span role="img" aria-label="open">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          role="presentation"
                        >
                          <path
                            d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                            fill="currentColor"
                            fillRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </label>
                  {isOpen && (
                    <Lista
                      ListaWidth={ListaWidth}
                      options={options}
                      inputRef={inputRef}
                      setIsOpen={setIsOpen}
                      filter={filter}
                      setFilter={setFilter}
                      selected={selected}
                      setSelected={setSelected}
                      changeSelected={changeSelected}
                    />
                  )}
                </>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
