import styled from 'styled-components';

export const ColumnSeparator = styled.div`
  color: rgba(224, 224, 224, 1);
  right: -12px;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  min-height: 56px;
  opacity: 1;
  top: 0;

  .MuiSvgIcon-root {
    width: 1.1em;
  }
`;

export const TableEmpty = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  h3 {
    font-size: 10pt;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;
